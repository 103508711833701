<template>
  <app-side-menu 
    :min-width="18"
    :fixed-menu="false">
    <aside 
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          {{ entityName }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link 
            :to="{ name: routeTypes.CustomerDetail.name, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : !validDetailGroup }"
            :data-tooltip="[ !validDetailGroup ? 'Validation errors' : '' ]"
            exact>
            <span 
              :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': !validDetailGroup }"
              :data-badge="!validDetailGroup ? '' : null">
              Detail Info
            </span>
          </router-link>
        </li>
        <li>
          <router-link 
            :to="{ name: routeTypes.CustomerAddress.name, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : $v.addressesGroup.$error }"
            :data-tooltip="[ $v.addressesGroup.$error ? 'Validation errors' : '' ]"
            exact>
            <span 
              :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.addressesGroup.$error }"
              :data-badge="$v.addressesGroup.$error ? '' : null">
              Addresses
            </span>
          </router-link>
        </li>
        <li>
          <router-link 
            :to="{ name: routeTypes.CustomerPhoneEmail.name, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : $v.emailsPhonesGroup.$error }"
            :data-tooltip="[ $v.emailsPhonesGroup.$error ? 'Validation errors' : '' ]"
            exact>
            <span 
              :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.emailsPhonesGroup.$error }"
              :data-badge="$v.emailsPhonesGroup.$error ? '' : null">
              Phones/Emails
            </span>
          </router-link>
        </li>
        <li>
          <router-link 
            :to="{ name: routeTypes.CustomerContact.name, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : $v.contactsGroup.$error }"
            :data-tooltip="[ $v.contactsGroup.$error ? 'Validation errors' : '' ]"
            exact>
            <span 
              :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.contactsGroup.$error }"
              :data-badge="$v.contactsGroup.$error ? '' : null">
              Contacts
            </span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import CustomerRoutes from './route-types'

export default {
  name: 'CustomerSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    entityName: {
      type: String,
      default: ''
    },
    isPerson: Boolean
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return CustomerRoutes
    },
    validNames() {
      return this.isPerson ? !this.$v.personGroup.$error : !this.$v.companyGroup.$error
    },
    validDetailGroup() {
      return this.validNames && !this.$v.detailGroup.$error
    }
  },
  mounted() {},
  methods: {}
}
</script>
