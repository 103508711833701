import { required, minLength } from 'vuelidate/lib/validators'
import addresses from '@/assets/validateForms/Address'
import phones from '@/assets/validateForms/Phones'
import emails from '@/assets/validateForms/Emails'
import contacts from '@/assets/validateForms/Contacts'
import { abnValidator, abnMinLength } from '@/components/validators'
import store from '@/store'

const countryCode = () => {
  const company = store.getters['company/company']
  return company.info.countryCode
}

const shouldValidateAbn = () => {
  if (countryCode() === 'AU') {
    return true
  } else {
    return false
  }
}

export default {
  provide: function () {
    return {
      $vv: this.$v
    }
  },
  validations: {
    companyGroup: ['entity.companyName'],
    personGroup: ['entity.firstname', 'entity.lastname'],
    detailGroup: ['entity.abn', 'entity.bsb', 'entity.customerGLCode'],
    emailsPhonesGroup: ['entity.emails', 'entity.phones'],
    addressesGroup: ['entity.addresses'],
    contactsGroup: ['entity.contacts'],
    entity: {
      companyName: {
        required
      },
      firstname: {
        required
      },
      lastname: {
        required
      },
      abn: {
        minLength: minLength(abnMinLength(countryCode())),
        validAbn: (value) => {
          if (shouldValidateAbn()) {
            return abnValidator(value)
          } else {
            return true
          }
        }
      },
      bsb: {
        minLength: minLength(6)
      },
      customerGLCode: {
        required
      },
      ...addresses,
      ...contacts,
      ...phones,
      ...emails
    }
  }
}
