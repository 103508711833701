<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-7">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Detail Info</p>
        <div class="field">
          <label class="label">Type</label>
          <div class="control">
            <input class="is-checkradio"
              id="company"
              type="radio"
              name="company"
              :value="false"
              v-model="entity.isPerson"
              @click="setIsPerson(false)">
            <label for="company">Company</label>
            <input class="is-checkradio"
              id="person"
              type="radio"
              name="person"
              :value="true"
              v-model="entity.isPerson"
              @click="setIsPerson(true)">
            <label for="person">Person</label>
          </div>
        </div>
        <div class="is-divider" />
        <div v-if="entity.isPerson"
          class="field">
          <label class="label">Title</label>
          <div class="control">
            <div class="select">
              <select v-model="entity.title">
                <option v-for="(a, b) in titles"
                  :value="a"
                  :key="a">{{ b }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <div v-if="entity.isPerson"
            class="columns">
            <div class="column">
              <div class="control">
                <label class="label">First Name</label>
                <input id="fName"
                  class="input is-capitalized"
                  type="text"
                  placeholder="First Name"
                  v-model="entity.firstname"
                  :class="{ 'is-danger' : $v.entity.firstname.$error, 'is-warning' : nameExists }"
                  @input="$v.entity.firstname.$touch(); checkNameExists()"
                  maxlength="40"
                  v-focus-inserted="{ focus: entity.isPerson }">
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.firstname.required">Firstname is required.
                </span>
                <span class="help is-warning"
                  v-if="nameExists">Name already exists.
                </span>
              </div>
            </div>
            <div class="column">
              <label class="label">Last Name</label>
              <div class="control">
                <input id="lName"
                  class="input is-capitalized"
                  type="text"
                  placeholder="Last Name"
                  v-model="entity.lastname"
                  :class="{ 'is-danger' : $v.entity.lastname.$error, 'is-warning' : nameExists }"
                  @input="$v.entity.lastname.$touch(); checkNameExists()"
                  maxlength="40">
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.lastname.required">Lastname is required.
                </span>
                <span class="help is-warning"
                  v-if="nameExists">Name already exists.
                </span>
              </div>
            </div>
          </div>
          <label v-if="!entity.isPerson"
            class="label">Company Name</label>
          <div v-if="!entity.isPerson"
            class="control">
            <input id="cName"
              class="input is-capitalized"
              type="text"
              placeholder="Company Name"
              v-model="entity.companyName"
              :class="{ 'is-danger' : $v.entity.companyName.$error, 'is-warning' : companyNameExists }"
              @input="$v.entity.companyName.$touch(); checkNameExists()"
              maxlength="120"
              v-focus-inserted="{ focus: !entity.isPerson }">
            <span class="help is-danger"
              v-if="$v.entity && !$v.entity.companyName.required">Company name is required.
            </span>
            <span class="help is-warning"
              v-if="companyNameExists">Company name already exists.
            </span>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field is-narrow">
              <label class="label">ABN</label>
              <div class="control has-icons-right">
                <the-mask v-if="$company.info.countryCode === 'AU'"
                  id="abn"
                  name="abn"
                  class="input"
                  placeholder="ABN"
                  v-model="entity.abn"
                  :mask="['## ### ### ###']"
                  :class="{'is-danger' : $v.entity.abn.$error}"
                  @input.native="$v.entity.abn.$touch()" />
                <input v-else
                  v-model="entity.abn"
                  id="abn"
                  ref="abn"
                  class="input is-uppercase"
                  maxlength="12"
                  @input="$v.entity.abn.$touch()">
                <span v-if="!$v.entity.abn.$error && entity.abn && $company.info.countryCode === 'AU'"
                  class="icon has-text-success is-right">
                  <i class="mdi mdi-check mdi-18px" />
                </span>
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.abn.minLength">ABN must be at least {{ $v.entity.abn.$params.minLength.min }} letters.</span>
                <span class="help is-danger"
                  v-else-if="$v.entity && !$v.entity.abn.validAbn">ABN format is invalid.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="columns">
            <div class="column is-narrow">
              <label class="label">BSB</label>
              <div class="field-body">
                <div class="field is-narrow">
                  <div class="control">
                    <the-mask class="input"
                      type="text"
                      placeholder="BSB"
                      v-model="entity.bsb"
                      :mask="['###-###']"
                      :class="{ 'is-danger' : $v.entity.bsb.$error }"
                      @input.native="$v.entity.bsb.$touch()" />
                    <span class="help is-danger"
                      v-if="$v.entity && !$v.entity.bsb.minLength">BSB must be at least {{ $v.entity.bsb.$params.minLength.min }} letters.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">Account No</label>
              <div class="control">
                <the-mask class="input"
                  type="text"
                  placeholder="Account No."
                  v-model="entity.accountNo"
                  :mask="['##########']" />
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">G/L Account</label>
          <div class="control">
            <multiselect v-if="glAccountListCombo"
              v-model="selectedGlAccountObject"
              :options="glAccountListCombo"
              placeholder="Select account"
              label="displayName"
              track-by="accountNo"
              :allow-empty="false">
              <span class="has-text-danger"
                slot="noResult">G/L account not found.</span>
            </multiselect>
            <span class="help is-danger"
              v-if="$v.entity && !$v.entity.customerGLCode.required">G/L Account is required.
            </span>
          </div>
        </div>
        <div class="pretty p-icon p-curve p-smooth p-bigger">
          <input type="checkbox"
            v-model="entity.exported"
            :disabled="!$userInfo.isSupportUser">
          <div class="state p-primary">
            <i class="icon mdi mdi-check" />
            <label>Exported</label>
          </div>
        </div>
        <div class="is-divider" />
        <div v-if="$user.hasDelete($route.meta.id)"
          class="buttons has-addons is-centered">
          <span class="button"
            :class="{ 'is-success is-selected' : entity.active }"
            @click="toggleActive(true)">
            <span class="icon is-small">
              <i class="mdi mdi-check" />
            </span>
            <span>Active</span>
          </span>
          <span class="button"
            :class="{ 'is-danger is-selected' : !entity.active }"
            @click="toggleActive(false)">
            <span class="icon is-small">
              <i class="mdi mdi-close" />
            </span>
            <span>In-Active</span>
          </span>
        </div>
      </article>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Vehicles</p>
        <table class="table is-bordered is-striped is-narrow is-fullwidth">
          <thead>
            <th>Rego</th>
            <th>Make &amp; Model</th>
          </thead>
          <tbody v-if="vehicles && vehicles.length > 0">
            <tr v-for="(vehicle) in vehicles"
              :key="vehicle.vehicleId">
              <td>
                {{ vehicle.rego }}
              </td>
              <td>{{ vehicle.makeModel }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="has-text-centered"
                colspan="2">
                <span icon="icon is-medium">
                  <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </article>
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import Titles from '@/enums/Titles'
import Multiselect from 'vue-multiselect'
import CustomerService from './CustomerService'
import _debounce from 'lodash.debounce'
import { EventHubTypes } from '@/enums'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'CustomerDetail',
  inject: ['$vv'],
  components: {
    TheMask,
    Multiselect
  },
  directives: {
    FocusInserted
  },
  props: {
    entity: null,
    vehicles: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      glAccountListCombo: null,
      selectedGlAccountObject: {},
      nameExists: false,
      companyNameExists: false,
      firstname: '',
      lastname: '',
      companyName: ''
    }
  },
  computed: {
    titles: function () {
      return Titles
    },
    $v() {
      return this.$vv ? this.$vv : this.$v
      // this.$parent.$v
    },
    validateError() {
      const validNames = this.entity.isPerson ? this.$v.personGroup.$error : this.$v.companyGroup.$error
      return validNames
    }
  },
  watch: {
    selectedGlAccountObject: function (newVal, oldVal) {
      if (newVal != null) {
        this.entity.customerGLCode = newVal.accountNo
      } else {
        this.entity.customerGLCode = ''
      }
    },
    'entity.isPerson': function (newValue, oldValue) {
      this.touchValidationObjects(newValue)
      if (newValue) {
        this.companyName = this.entity.companyName
        this.entity.companyName = ''
        this.entity.firstname = this.firstname
        this.entity.lastname = this.lastname
      } else {
        this.entity.companyName = this.entity.isNew && !this.companyName ? this.entity.lastname : this.companyName
        this.firstname = this.entity.firstname
        this.lastname = this.entity.lastname
        this.entity.firstname = ''
        this.entity.lastname = ''
      }
    },
    'entity.customerGLCode': function (newVal) {
      this.onReload()
    }
  },
  created() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, (data) => {
      this.onEntitySaved(data)
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.onReload()
    })
  },
  mounted() {
    this.getData()

    this.$v.detailGroup.$touch()
    this.touchValidationObjects(this.entity.isPerson)
  },
  methods: {
    setIsPerson: function (isPerson) {
      this.entity.isPerson = isPerson
      // this.$forceupdate()
    },
    async getData() {
      this.glAccountListCombo = await CustomerService.getGlAccountListComboProto()
      this.selectedGlAccountObject = this.glAccountListCombo.find((g) => g.accountNo === this.entity.customerGLCode)
    },
    touchValidationObjects(isPerson) {
      if (isPerson) {
        this.$v.companyGroup.$reset()
        this.$v.personGroup.$touch()
      } else {
        this.$v.companyGroup.$touch()
        this.$v.personGroup.$reset()
      }
    },
    checkInUse() {
      this.$emit('entityDelete')
    },
    checkNameExists: _debounce(async function () {
      await this.assertNameExists()
    }, 500),
    async assertNameExists() {
      if (this.entity.isPerson) {
        const name = `${this.entity.lastname}, ${this.entity.firstname}`
        this.nameExists = await CustomerService.assertNameExists(this.entity.id, name)
      } else {
        const name = this.entity.companyName
        this.companyNameExists = await CustomerService.assertNameExists(this.entity.id, name)
      }
    },
    onEntitySaved() {
      this.nameExists = false
      this.companyNameExists = false
    },
    onReload() {
      this.selectedGlAccountObject = this.glAccountListCombo.find((g) => g.accountNo === this.entity.customerGLCode)
    },
    toggleActive(active) {
      this.entity.active = active
      if (active) {
        this.assertNameExists()
      } else {
        this.checkInUse()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
