<template>
  <div v-if="entity">
    <app-detail-header
      ref="sectionHeader"
      :title="`Customer ${$route.meta.title}`"
      :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && entity.active && !entity.isNew"
      :menu-code-name="menuCodeName"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <customer-side-menu
          v-if="entity"
          ref="sideMenu"
          :entity-name="entityName"
          :is-person="entity.isPerson" />
      </div>
      <div class="column">
        <router-view
          :entity="entity"
          :vehicles="vehicles"
          @entityDelete="onEntityDelete()" />
      </div>
    </div>
    <unsaved-modal
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal
      :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal
      v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(false, false, deleteAndSave)"
      @cancel="deleteEntity(false, true, false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Customer is in use</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entityName }}</span> is in use. Set to
        <span class="tag is-danger">In-Active</span> anyway?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import CustomerService from '@/views/customer/CustomerService'
import CustomerSideMenu from '@/views/customer/CustomerSideMenu'
import CustomerValidation from './CustomerValidation'
import EventBus from '@/components/EventBus'
import CustomerRoutes from './route-types'
import QuickInvoiceRoutes from '@/views/quickinvoice/route-types'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import { AppHeaderButtonTypes, EventHubTypes, AssetTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import HttpStatus from '@/components/http-status'
import { QuickInvoiceAssetPayload } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'CustomerView',
  components: {
    AppDetailHeader,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal,
    CustomerSideMenu
  },
  mixins: [CustomerValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    name: String
  },
  data() {
    return {
      routeValues: {
        customerId: this.$route.params.customerId
      },
      entity: null,
      menuCodeName: 'A016',
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      deleteAndSave: false,
      toRoute: null,
      vehicles: null
    }
  },
  computed: {
    routeTypes() {
      return CustomerRoutes
    },
    reportUrl: function() {
      return process.env.VUE_APP_ROOT_URI + '/Modules/Reports/PrintPreview.aspx?reportname=rptTableCustomerDetails'
    },
    buttonCSS: function() {
      return 'is-inverted'
    },
    CustomerRoutes: function() {
      return CustomerRoutes
    },
    encodedFilter: function() {
      return this.$route.query.filter
    },
    entityName() {
      if (this.entity.companyName) {
        return this.entity.companyName
      } else {
        const firstname = this.entity.firstname ? this.entity.firstname.trim() : ''
        const lastname = this.entity.lastname ? this.entity.lastname.trim() : ''
        return `${lastname || ''}${lastname && firstname ? ', ' : ''}${firstname}`
      }
    },
    validateError() {
      const validNames = this.entity.isPerson ? this.$v.personGroup.$error : this.$v.companyGroup.$error
      return (
        this.$v.$error ||
        this.$v.detailGroup.$error ||
        this.$v.contactsGroup.$error ||
        validNames
      )
    },
    returnUrlKey() {
      return `${this.$userInfo.sessionId}|customer|returnUrl`
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  async created() {
    // console.log(this.$route.query.mode)
    if (this.$route.query.mode === 'new') {
      this.$showSpinner('Creating...')
      await this.addStoreItem(this.name)
      this.entity = _cloneDeep(this.currentSnapshot)
      this.persistQueries()
      this.replaceRoute(this.entity.id)
      this.$hideSpinner()
    } else {
      this.getEntity()
    }
  },
  mounted: function() {},
  beforeDestroy() {
    // this.clearSessionStorage()
    // this.clearSnapshots(this.entity.id)
  },
  methods: {
    async getEntity(reset = false) {
      this.$showSpinner()
      // this.entity = null
      try {
        if (reset) {
          this.clearSnapshots(this.$route.params.customerId)
        }
        if (!this.currentSnapshot) {
          // eslint-disable-next-line
          ;[, this.vehicles] = await Promise.all([this.getStoreItem(this.$route.params.customerId), CustomerService.getVehicles(this.$route.params.customerId)])
        } else {
          this.vehicles = await CustomerService.getVehicles(this.$route.params.customerId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.deleteAndSave = true
          this.deleteEntity(true, false, true)
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async save(isDelete) {
      if (this.validateError) {
        this.$notification.validationError('Customer')
        return false
      }
      const title = 'Customer'
      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          await CustomerService.postCustomer(this.entity)
        } else if (this.snapshotDiff) {
          await CustomerService.putCustomer(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return false
        }
        // await this.removeStoreItem(this.entity.id)
        this.$notification.success('Customer', isDelete ? 'Customer set to In-Active' : 'Save successful')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('Customer', e)
        }
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.returnRoute && !_isEmpty(this.returnRoute)) {
        this.$router.push({
          path: this.returnRoute.path,
          query: this.returnRoute.query
        })
      } else if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        // const returnUrl = sessionStorage.getItem(this.returnUrlKey)
        // if it comes from url => redirect after save
        if (this.returnUrl) {
          if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
            this.isUnsavedModalActive = true
          } else {
            this.clearSessionStorage()
            this.clearSnapshots(this.entity.id)
            this.$showSpinner('Returning...')
            this.redirectToQueryUrl(this.returnUrl)
          }
        } else {
          if (this.entity.isNew && this.isSkipSave) {
            // cleanup vuex store
            this.clearSnapshots(this.entity.id)
          }
          this.$router.push({
            name: CustomerRoutes.CustomerListView.name,
            filter: this.filter,
            query: {
              filter: this.encodedFilter
            }
          })
        }
      }
    },
    print() {
      let params = Object.assign(
        {
          CompanyID: this.$userInfo.companyId,
          CustomerID: this.entity.id
        }
      )

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.id
      emailer.subject = 'Customer Detail Table'
      emailer.reportName = 'rptTableCustomerDetails'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    delete() {
      this.entity.active = false
      this.save(true)
    },
    redirectToQueryUrl(returnUrl) {
      if ((returnUrl.indexOf('QuickInvoice') !== -1 || returnUrl.indexOf('MiscQuote') !== -1) && !this.entity.isNew) {
        window.location.href = this.updateQueryStringParameter(returnUrl, 'cid', this.entity.id)
      } else {
        window.location.href = returnUrl
      }
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
      var separator = uri.indexOf('?') !== -1 ? '&' : '?'
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2')
      } else {
        return uri + separator + key + '=' + value
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.toRoute = null
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.getEntity(true)
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async deleteEntity(check, value, saveEntity) {
      this.isConfirmModalActive = false
      const isInUse = check ? await CustomerService.assertUsed(this.entity.id) : false
      if (isInUse) {
        this.isConfirmModalActive = true
      } else {
        this.entity.active = value
        if (saveEntity) {
          this.save(true)
        }
      }
    },
    async onEntityDelete() {
      this.deleteAndSave = false
      const isInUse = await CustomerService.assertUsed(this.entity.id)
      if (isInUse) {
        this.isConfirmModalActive = true
      }
    },
    clearSessionStorage() {
      this.removeQueries()
      // sessionStorage.removeItem(this.returnUrlKey)
    },
    persistQueries() {
      if (this.isNew) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|customer|isNew`, this.isNew)
      }
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|customer|returnUrl`, this.returnUrl)
      }
      if (this.name) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|customer|name`, this.name)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|customer|isNew`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|customer|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|customer|name`)
    },
    replaceRoute(id) {
      // console.log(this.returnUrl)
      const newMeta = Object.assign(this.$route.meta, {
        isNew: true,
        returnUrl: this.returnUrl,
        name: this.name
      })
      this.$router.replace({
        name: CustomerRoutes.CustomerDetail.name,
        params: { customerId: id },
        meta: newMeta
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    console.log(this.snapshotDiff)
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      if (to.name === QuickInvoiceRoutes.QuickInvoiceDetail.name && (!this.entity.isNew || this.isSaveContinue) && !this.isSkipSave) {
        const payload = new QuickInvoiceAssetPayload(this.entity.id, AssetTypes.Customer, this.entity.customerGLCode)
        this.setQuickInvoiceAsset(payload)
      } else if (to.name === 'VehicleDetail' && (!this.entity.isNew || this.isSaveContinue) && !this.isSkipSave) {
        console.log('setVehicleCustomer')
        const customer = this.entity.isPerson ? `${this.entity.lastname}, ${this.entity.firstname}` : this.entity.companyName
        this.setVehicleCustomer({
          id: to.params.vehicleId,
          customer: {
            key: this.entity.id,
            value: customer
          }
        })
      }
      this.clearSnapshots(this.entity.id)
      this.clearSessionStorage()
      this.setReturnRoute({})
      next()
    }
  }
}
</script>
